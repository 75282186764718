 <!-- Incoming Call UI -->
 <div *ngIf="showIncomingUI" class="call-dialog-content" @fadeIn>
  <h2 mat-dialog-title>Incoming Call</h2>
  <mat-dialog-content>
    <div class="caller-info">
      <div class="avatar">
        <div class="avatar-inner">
          <div class="initials">{{ contactInitials }}</div>
        </div>
      </div>
      <div>
        <h3>{{ contactName }}</h3>
        <p>{{ contactNumber }}</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="warn" (click)="rejectCall()">Reject</button>
    <button mat-raised-button color="primary" (click)="acceptCall()">Accept</button>
  </mat-dialog-actions>
</div>

<!-- Outgoing Call UI -->
<div *ngIf="showOutgoingUI" class="call-container" @fadeIn>
  <div class="call-status">
    <div class="status-text">{{ callStatusText }}</div>
    <div class="call-timer" *ngIf="isConnected">{{ formatTime(callDuration) }}</div>
  </div>
  
  <div class="avatar-container">
    <div class="avatar" [ngClass]="{'pulse': isPulsing}" [@pulse]="pulseState">
      <div class="avatar-inner">
        <div class="initials">{{ contactInitials }}</div>
      </div>
    </div>
    <div class="connecting-dots" *ngIf="!isConnected">
      <span class="dot" [ngClass]="{'active': connectingDot === 1}"></span>
      <span class="dot" [ngClass]="{'active': connectingDot === 2}"></span>
      <span class="dot" [ngClass]="{'active': connectingDot === 3}"></span>
    </div>
  </div>
  
  <div class="contact-info">
    <div class="contact-name">{{ contactName }}</div>
    <div class="contact-number">{{ contactNumber }}</div>
  </div>
  
  <div class="call-controls">
    <button class="control-button" (click)="toggleMute()">
      <mat-icon>{{ isMuted ? 'mic_off' : 'mic' }}</mat-icon>
      <span class="control-label">{{ isMuted ? 'Unmute' : 'Mute' }}</span>
    </button>

    <button class="control-button" (click)="toggleHold()" >
      <mat-icon>{{ isOnHold ? 'play_arrow' : 'pause' }}</mat-icon>
      <span class="control-label">{{ isOnHold ? 'UnHold' : 'Hold' }}</span>
    </button>
    
    <!-- <button class="control-button" (click)="toggleSpeaker()">
      <mat-icon>{{ isSpeakerOn ? 'volume_up' : 'volume_down' }}</mat-icon>
      <span class="control-label">Speaker</span>
    </button> -->
    
    <!-- <button class="control-button" (click)="toggleKeypad()">
      <mat-icon>dialpad</mat-icon>
      <span class="control-label">Keypad</span>
    </button> -->
  </div>
  
  <div class="keypad-container" *ngIf="showKeypad" @fadeIn>
    <div class="keypad-row" *ngFor="let row of keypadRows">
      <button class="keypad-button" *ngFor="let key of row" (click)="sendDtmf(key)">
        <div class="key-number">{{ key }}</div>
        <div class="key-letters">{{ getKeyLetters(key) }}</div>
      </button>
    </div>
    <button class="keypad-close" (click)="toggleKeypad()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="end-call-container">
    <button class="end-call-button" (click)="endCall()">
      <mat-icon>call_end</mat-icon>
    </button>
  </div>
</div>

<!-- Active Call UI -->
<div *ngIf="showActiveCallUI" class="call-dialog-content" @fadeIn>
  <h2 mat-dialog-title>Call in Progress</h2>
  <mat-dialog-content>
    <div class="caller-info">
      <div class="avatar">
        <div class="avatar-inner">
          <div class="initials">{{ contactInitials }}</div>
        </div>
      </div>
      <div>
        <h3>{{ contactName }}</h3>
        <p>{{ contactNumber }}</p>
      </div>
    </div>
    <div *ngIf="callDuration > 0" class="call-duration">
      Call duration: {{ formatTime(callDuration) }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="call-controls">
    <button mat-icon-button color="primary" (click)="toggleMute()" [matTooltip]="isMuted ? 'Unmute' : 'Mute'">
      <mat-icon>{{ isMuted ? 'mic_off' : 'mic' }}</mat-icon>
    </button>
    
    <!-- <button mat-icon-button color="primary" (click)="toggleSpeaker()" [matTooltip]="isSpeakerOn ? 'Speaker Off' : 'Speaker On'">
      <mat-icon>{{ isSpeakerOn ? 'volume_up' : 'volume_down' }}</mat-icon>
    </button> -->

    <button mat-icon-button color="primary" (click)="toggleHold()" [matTooltip]="isOnHold ? 'Resume Call' : 'Hold Call'">
      <mat-icon>{{ isOnHold ? 'play_arrow' : 'pause' }}</mat-icon>
    </button>
    
    <button mat-icon-button color="warn" (click)="endCall()" matTooltip="End Call">
      <mat-icon>call_end</mat-icon>
    </button>
  </mat-dialog-actions>
</div>

<audio #remoteAudio autoplay></audio>
