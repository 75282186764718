import { Injectable, OnDestroy } from '@angular/core';
import { SipConfig, SipService } from './sip.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SipInitService implements OnDestroy {
  private config: SipConfig | null = null;
  private registrationSubscription: Subscription;

  constructor(private sipService: SipService, private snackBar: MatSnackBar) {}

  ngOnDestroy(): void {
    if (this.registrationSubscription) {
      this.registrationSubscription.unsubscribe();
    }
    // Ensure we clean up SIP connection when service is destroyed
    this.sipService.unregister();
  }

  /**
   * Initialize with credentials - called once when app loads
   */
  initializeWithCredentials(config: SipConfig): void {
    this.config = config;

    // Subscribe to monitor connection state
    this.registrationSubscription =
      this.sipService.registrationState$.subscribe((state) => {
        console.log('SIP registration state:', state);
      });

    // Perform initial registration
    this.register();

    // Handle page visibility changes (for page refresh/reload)
    // this.setupVisibilityHandler();
  }

  /**
   * Register with the SIP server
   */
  private register(): void {
    if (!this.config) return;

    this.sipService.initialize(this.config).subscribe(
      (success) => {
        if (success) {
          console.log('SIP registration successful');
        } else {
          console.warn('SIP registration returned false');
        }
      },
      (error) => {
        console.error('SIP registration error:', error);
        this.snackBar.open(`SIP Error: ${error.message}`, 'Close');
      }
    );
  }

  /**
   * Set up visibility change handler for page reload
   */
  private setupVisibilityHandler(): void {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // Page became visible again (after reload or tab switch)
        console.log('Page visible, checking SIP registration...');
        this.sipService.registrationState$.subscribe((regState) => {
          // Use regState here
          if (regState.status !== 'registered') {
            console.log('Not registered, re-registering SIP...');
            // We're not registered, likely after page reload, so register again
            this.sipService.unregister(); // Clean up any existing sessions
            // setTimeout(() => this.register(), 1000); // Re-register with a small delay
          }
        });
      } else {
        // Page is hidden (user navigated away or is reloading)
        console.log('Page hidden, preparing for potential reload...');
      }
    });
  }
}
