import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.handleInternalMarkUrl();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(body: any) {
    return this.http.post<any>(`${environment.apiUrl}api/logininfo/login`, body).pipe(
      map((user) => user),
      catchError((error) => throwError(() => error))
    );
  }

  validate(body: any) {
    return this.http.post<any>(`${environment.apiUrl}api/logininfo/validate-otp`, body).pipe(
      map((user) => user),
      catchError((error) => throwError(() => error))
    );
  }

  passwordReset(body: any) {
    return this.http.post<any>(`${environment.apiUrl}api/logininfo/passwordReset`, body).pipe(
      map((user) => user),
      catchError((error) => throwError(() => error))
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  firebasePushIdUpdate(body: any) {
    return this.http.post<any>(`${environment.apiUrl}api/pushNotificationinfo/pushNotification`, body).pipe(
      map((user) => user),
      catchError((error) => throwError(() => error))
    );
  }

  clearLocalStorageItems(): void {
    setTimeout(() => {
      ['gridstaff_grid', 'gridstudent_grid', 'gridteachers_grid'].forEach((e: string) => {
        localStorage.removeItem(e);
      });
    }, 1500);
  }

  ensureWriteAccess(component: string) {
    const write_access = JSON.parse(this.currentUserValue.write_access || null);
    if (write_access && this.currentUserValue.role === 'Admin' && this.currentUserValue.department_name !== 'super admin') {
      return write_access.indexOf(component) > -1;
    }
    return true;
  }

  handleInternalMarkUrl() {
    if (this.currentUserValue) {
      const isAdrian = this.currentUserValue.school_id === 4;
      this.urlComponentMapping[isAdrian ? 'admin/examresult/assessment-result' : 'admin/examresult/internal-marks'] = 'Assessment Result';
    }
  }

  urlComponentMapping: any = {
    '/admin/dashboard/main': 'DashBoard1',
    '/admin/dashboard/dashboard2': 'DashBoard2',
    '/admin/departments/all-departments': 'All Departments',
    '/admin/departments/add-department': 'Add Department',
    '/admin/houses/add-houses': 'All Houses',
    '/admin/houses/add-house': 'Add House',
    '/admin/subject/all-subject': 'All Subject',
    '/admin/class/all-class': 'All Class',
    '/admin/class/add-class': 'Add Class',
    '/admin/teachers/all-teachers': 'All Teachers',
    '/admin/teachers/about-teacher': 'All Teachers',
    '/admin/teachers/substitute-teacher': 'Substitute Teacher',
    '/admin/teachers/add-teacher': 'Add Teacher',
    '/admin/subject-class-teacher-mapping/subject-class-teacher-mapping': 'Subject Class Teacher',
    '/admin/students/all-students': 'All Students',
    '/admin/students/about-students': 'All Students',
    '/admin/students/add-student': 'Add Student',
    '/admin/students/analysis': 'Student Analysis',
    '/admin/students/student-promotion': 'Student Promotion',
    '/admin/students/consolidated': 'Student Consolidated',
    '/admin/rating/student-rating': 'Rating',
    '/admin/rating/exam-rating': 'Exam Rating',
    '/admin/configuration/internal-marks': 'Internal Marks',
    '/admin/configuration/rating-params': 'Rating params',
    '/admin/staff/all-staff': 'All Staff',
    '/admin/staff/add-staff': 'Add Staff',
    '/admin/students/student-attendance': 'Student Attendance',
    '/admin/attendance/teacher': 'Teacher Attendance',
    '/admin/attendance/staff': 'Staff Attendance',
    '/admin/exam/all-exams': 'All Exams',
    '/admin/exam/add-exam': 'Add Exam',
    '/admin/examresult/all-examresult': 'Exam Result',
    '/admin/fees/fee-structure': 'Fee Strucutre',
    '/admin/fees/fee-allocation': 'Fee Allocation',
    '/admin/fees/fee-entry': 'Student Fee Entry',
    '/admin/fees/student-fees-detail': 'Student Fee Details',
    '/admin/action-pending': 'Action Pending',
    '/admin/courses/all-courses': 'All Courses',
    '/admin/courses/about-course': 'All Courses',
    '/admin/courses/add-course': 'Add Course',
    '/admin/library/all-assets': 'All Library Assests',
    '/admin/library/add-asset': 'Add Library Asset',
    '/admin/library/add-student-asset': 'Library Assest Entry',
    '/admin/transport/stop': 'Stop',
    '/admin/transport/route': 'Route',
    '/admin/transport/bus': 'Bus',
    '/admin/transport/driver-allocation': 'Driver Allocation',
    '/admin/transport/daily-bus-tracker': 'Daily Bus Tracker',
    '/admin/holidays/all-holidays': 'All Holiday',
    '/admin/holidays/add-holiday': 'Add Holiday',
    '/admin/leave/all-leaves': 'Apply Leave',
    '/admin/leave-request/student': 'Student',
    '/admin/leave-request/staff': 'Staff',
    '/admin/leave-request/teacher': 'Teacher',
    '/admin/leave-request/admin': 'Admin',
    '/admin/events/all-events': 'All Events',
    '/admin/events/edit-events': 'All Events',
    '/admin/events/add-events': 'Add Events',
    '/admin/noticeboard/all-noticeboard': 'All Noticeboards',
    '/admin/noticeboard/edit-noticeboard': 'All Noticeboards',
    '/admin/noticeboard/add-noticeboard': 'Add Noticeboard',
    '/admin/accessmanagement/all-accessmanagement': 'Access Management',
    '/admin/broadcast/all-broadcast': 'Broadcast',
    '/admin/inventory/all-inventory': 'Inventory',
    '/admin/period/all-period': 'Period',
    '/chat': 'Ask Me',
    '/admin/gallery/all-gallery': 'Gallery',
    '/admin/special-day/greets': 'Special Day Greets'
  };
}
