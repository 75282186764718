import { Component, ViewChild, ElementRef } from '@angular/core';
import { Configuration, OpenAIApi } from 'openai';
import { environment } from 'src/environments/environment';
import { ChatWithBot, ResponseModel } from '../../models/gpt-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-ask-query',
  templateUrl: './ask-query.component.html',
  styleUrls: ['./ask-query.component.scss'],
})
export class AskQueryComponent {
  chatConversation: ChatWithBot[] = [];
  @ViewChild('chatMessages') chatMessages: ElementRef;
  response!: ResponseModel | undefined;
  promptText = '';
  showSpinner = false;
  breadscrums: any[] = [
    {
      title: 'Ask Me',
      items: [],
      active: 'Chat',
    },
  ];
  private openAiChatCompletionapiKey = environment.openAiChatCompletionApiKey;
  private openAiChatCompletionUrl = environment.openAiChatCompletionUrl;
  constructor(
    private http: HttpClient,
  ) { }

  checkResponse() {
    this.pushChatContent(this.promptText, 'you', 'person');
    this.invokeGPT();
    this.promptText = '';
    setTimeout(() => this.scrollToBottom());
  }

  getText(data: string) {
    return data.split('\n').filter((f) => f.length > 0);
  }

  pushChatContent(content: string, person: string, cssClass: string) {
    const chatToPush: ChatWithBot = {
      person: person,
      response: content,
      cssClass: cssClass,
    };
    this.chatConversation.push(chatToPush);
  }

  async invokeGPT() {
    if (this.promptText.length < 2) return;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.openAiChatCompletionapiKey,  // Azure OpenAI uses 'api-key' in the header
    });

    const requestData = {
      "messages": [
        {
          "role": "system",
          "content": `You are an AI voice bot created to support academic learning for students and educators by answering questions and clarifying doubts. Ensure all interactions are polite, educational, and appropriate for a school-aged audience. If a query is unrelated to academics or outside safe and suitable topics, respond respectfully and encourage a return to relevant subjects.

    You should detect and adapt to the user's preferred language, primarily supporting English, Tamil, Hindi, Kannada, and other Indian languages commonly spoken by students. Default to English unless another language is explicitly requested, and continue in that language until directed otherwise.

    Since most users will be school students, keep responses clear, concise, and tailored to a school-level understanding. Avoid using languages outside the listed preferences, and ensure all content is accessible, safe, and conducive to a positive learning environment.`
        },
        {
          "role": "user",
          "content": this.promptText
        }
      ],
      "temperature": 0.7,
      "top_p": 0.95,
      "max_tokens": 4000
    };

    this.showSpinner = true;
    try {
      const apiResponse = await this.http
        .post<any>(this.openAiChatCompletionUrl, requestData, { headers })
        .toPromise();

      const responseText = apiResponse?.choices[0]?.message?.content.trim();
      console.log(responseText);
      this.pushChatContent(responseText, 'bot', 'bot');
      this.showSpinner = false;
    } catch (error) {
      console.error('Error from Azure OpenAI API:', error);
      this.showSpinner = false;
      this.pushChatContent(
        "Sorry We Couldn't Process the request! Try later",
        'bot',
        'bot'
      );
      throw error;
    }

    setTimeout(() => this.scrollToBottom());
  }
  scrollToBottom() {
    const element = this.chatMessages.nativeElement;
    element.scrollTop = element.scrollHeight + 30;
  }
  onCopy(event: any) {
    const currentCopyEle = event.target;
    const messageEle = currentCopyEle.closest('.message');
    const range = document.createRange();
    range.selectNode(messageEle);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    currentCopyEle.closest('.copy-btn').querySelector('.mdc-button__label').innerText = 'Copied!';
    setTimeout(() => {
      currentCopyEle.closest('.copy-btn').querySelector('.mdc-button__label').innerText = 'Copy';
    }, 2000);
  }
}
