import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { StatusBar } from '@capacitor/status-bar';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { Platform } from '@ionic/angular';
import * as packageJson from '../../package.json';
import { environment } from '../environments/environment';
import { ApiService } from './api.service';
import { ForceupdateComponent } from './shared/modules/authentication/forceupdate/forceupdate.component';
import { CommonService } from './shared/services/common.service';
import { AuthService } from './shared/services/auth.service';
import {
  CallState,
  RegistrationState,
  SipConfig,
  SipService,
} from './shared/services/sip.service';
import { SipInitService } from './shared/services/sipInitService';
import { Observable, Subscription } from 'rxjs';
import { CallDialogComponent } from './admin/modules/students/call-dialog/call-dialog.component';
import { Role } from './shared/models/role';

@Component({
  selector: 'app-root',
  template: `
    <app-page-loader></app-page-loader>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  currentUrl: string;
  message: any = null;
  version: any;
  lastActiveUrl = localStorage.getItem('lastActiveUrl');
  history: any = [];
  
  // Firebase messaging
  private messaging: any;
  private fcmTokenSubscription: Subscription;

  registrationState$: Observable<RegistrationState>;
  callState$: Observable<CallState>;
  private callStateSubscription: Subscription;
  private activeCallDialogRef: MatDialogRef<CallDialogComponent> = null;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public platform: Platform,
    private common: CommonService,
    private apiService: ApiService,
    private location: Location,
    private authService: AuthService,
    private sipService: SipService,
    private sipInitService: SipInitService
  ) {
    const packageJsonData = packageJson;
    this.version = packageJsonData.version;
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        localStorage.setItem('lastActiveUrl', routerEvent.url);
        this.history.push(routerEvent.urlAfterRedirects);
      }
      window.scrollTo(0, 0);
    });
    this.registrationState$ = this.sipService.registrationState$;
    this.callState$ = this.sipService.callState$;
  }

  ngOnInit(): void {
    // Initialize SIP on component init
    if (
      this.authService.currentUserValue &&
      this.authService.currentUserValue?.sip_extension &&
      this.authService.currentUserValue.role === Role.Admin
    ) {
      this.initSip();

      // Subscribe to call state changes
      this.callStateSubscription = this.callState$.subscribe(
        (state: CallState) => {
          if (this.authService.currentUserValue == null) {
            this.sipService.unregister();
            return;
          }
          // Handle call state changes
          if (
            state.callStatus === 'connecting' &&
            !state.isInCall &&
            this.authService.currentUserValue.isIncomingEnabled
          ) {
            this.showIncomingCallDialog(state);
          }
        }
      );
    }
    this.initializeApp();

    if (this.lastActiveUrl) {
      this.router.navigateByUrl(this.lastActiveUrl);
    } else {
      this.router.navigate(['/authentication/signin']);
    }
    window.addEventListener('offline', () => {
      this.apiService.showNotification(
        'snackbar-info',
        'You are offline now.',
        3000
      );
    });
    window.addEventListener('online', () => {
      this.apiService.showNotification(
        'snackbar-success',
        'Back to online.',
        3000
      );
    });
  }

  initSip(): void {
    let sipConfig: SipConfig = {
      sipUri: `sip:${this.authService.currentUserValue.sip_username}`,
      sipPassword: this.authService.currentUserValue.sip_password,
      wsUri: 'wss://tata.in-sync.co.in:8089/ws',
    };
    console.log(sipConfig);
    // Use the SIP initialization service which handles reconnection
    this.sipInitService.initializeWithCredentials(sipConfig);
  }

  private showIncomingCallDialog(callState: CallState) {
    // Prevent multiple dialogs
    if (this.activeCallDialogRef) {
      return;
    }
    this.playIncomingCallAudio();
    this.activeCallDialogRef = this.dialog.open(CallDialogComponent, {
      width: '350px',
      disableClose: true,
      data: {
        callState: callState,
        isIncoming: true,
        sipService: this.sipService,
        disableClose: true,
      },
    });

    this.activeCallDialogRef.afterClosed().subscribe(() => {
      this.activeCallDialogRef = null;
    });
  }

  playIncomingCallAudio() {
    let playCount = 0;
    const audio = new Audio('assets/audio/incoming_call.wav');
    audio.muted = true; // Start muted
    audio
      .play()
      .then(() => {
        audio.muted = false; // Unmute after play starts
      })
      .catch((error) => console.error('Playback failed:', error));
    audio.addEventListener('ended', () => {
      setTimeout(() => {
        playCount++;
        if (playCount < 3) {
          audio.currentTime = 0;
          audio.play();
        }
      }, 200);
    });
  }

  ngOnDestroy() {
    if (this.callStateSubscription) {
      this.callStateSubscription.unsubscribe();
    }
    
    if (this.fcmTokenSubscription) {
      this.fcmTokenSubscription.unsubscribe();
    }

    // Close any open dialog on component destruction
    if (this.activeCallDialogRef) {
      this.activeCallDialogRef.close();
    }
    
    // Remove event listeners
    window.removeEventListener('offline', () => {});
    window.removeEventListener('online', () => {});
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.initFCMPushNotification();
        this.getVersion();
        this.addBackButtonListener();
        this.showStatusBar();
      } else {
        this.initFCMWebNotification();
      }
    });
  }

  showStatusBar(): void {
    StatusBar.show();
  }

  navigateBack(): void {
    const validPaths: Array<string> = [
      '/teacher/dashboard',
      '/admin/dashboard/main',
      '/student/dashboard',
      '/authentication/signin',
    ];
    if (
      this.history.length &&
      !validPaths.includes(this.history[this.history.length - 1])
    ) {
      this.history.pop();
      this.location.back();
    } else {
      this.history = [];
      App.exitApp();
    }
  }

  addBackButtonListener(): void {
    document.addEventListener('backbutton', this.navigateBack.bind(this));
  }

  getVersion(): void {
    const type: string = environment.isAdminApp
      ? 'admin'
      : environment.isTeacherApp
      ? 'teacher'
      : 'student';
    this.apiService
      .get('api/logininfo/version', { params: { type } })
      .subscribe((data: any) => {
        if (!data || data.length === 0) return;
        const currentVersion = this.version;
        const latestVersion = data[0]?.version;
        if (
          this.hasUpdate(currentVersion, latestVersion) &&
          this.platform.is('android') &&
          data[0]?.android?.data[0] == 1
        ) {
          this.dialog.open(ForceupdateComponent, { disableClose: true });
        } else if (
          data &&
          this.platform.is('ios') &&
          data[0]?.ios?.data[0] == 1
        ) {
          this.logout();
          this.dialog.open(ForceupdateComponent, { disableClose: true });
        }
      });
  }

  logout(): void {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
    this.authService.clearLocalStorageItems();
  }

  // Initialize FCM Push Notifications for native mobile apps (Capacitor)
  initFCMPushNotification(): void {
    // Request permission to use push notifications
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.log('Push notification permission denied');
      }
    });

    // On successful registration
    PushNotifications.addListener('registration', (token: Token) => {
      if (token && token.value) {
        localStorage.setItem('firebaseToken', token.value);
        this.sendTokenBackend(token.value);
        console.log('Push registration success, token: ' + token.value);
      }
    });

    // On registration error
    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on FCM registration: ', error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push notification received:', notification);
        this.showNotificationInApp(notification);
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push notification clicked:', notification);
        this.handleNotificationClick(notification.notification.data);
      }
    );
  }

// Updated initFCMWebNotification to clean up duplicates and ensure single registration
initFCMWebNotification(): void {
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications');
    return;
  }

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      this.common.setPermission(true);

      // Unregister any existing Firebase default service workers
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        const unregisterPromises = registrations
          .filter(reg => reg.scope.includes('firebase-cloud-messaging-push-scope'))
          .map(reg => reg.unregister().then(() => {
            console.log('Unregistered Firebase default service worker:', reg.scope);
          }));
        
        return Promise.all(unregisterPromises).then(() => {
          // Now register our custom service worker
          return navigator.serviceWorker.register('/firebase-messaging-sw.js');
        });
      })
      .then((registration) => {
        console.log('Custom Service Worker registered with scope:', registration.scope);

        // Initialize Firebase app if not already initialized
        if (!firebase.apps.length) {
          firebase.initializeApp(environment.firebase);
        }
        
        // Get messaging instance
        this.messaging = firebase.messaging();
        
        // Request FCM token using our custom service worker registration
        this.requestFCMToken(registration);
        
        // Remove the foreground onMessage handler to prevent duplicate notifications.
        // All notifications will be handled by the service worker.
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
    } else {
      console.log('Notification permission denied');
      this.common.setPermission(false);
    }
  });
}

requestFCMToken(registration?: ServiceWorkerRegistration): void {
  if (!this.messaging) {
    this.messaging = firebase.messaging();
  }
  
  // Prepare options including our custom service worker registration if available
  const options: { vapidKey: string, serviceWorkerRegistration?: ServiceWorkerRegistration } = {
    vapidKey: environment.firebase.vapidKey
  };
  if (registration) {
    options.serviceWorkerRegistration = registration;
  }
  
  this.messaging.getToken(options)
    .then((currentToken: any) => {
      if (currentToken) {
        console.log('Firebase token obtained:', currentToken);
        localStorage.setItem('firebaseToken', currentToken);
        this.sendTokenBackend(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        this.common.setPermission(false);
      }
    })
    .catch((error: any) => {
      console.error('Error getting Firebase token:', error);
      this.common.setPermission(false);
    });
}


showWebNotification(payload: any): void {
  if (!payload || !payload.notification) {
    return;
  }
  
  const notificationOptions = {
    body: payload.notification.body || '',
    icon: payload.notification.icon || 'assets/icons/app-icon.png',
    badge: 'assets/icons/badge-icon.png',
    data: payload.data || {}
  };
  
  const notification = new Notification(payload.notification.title || 'New Notification', notificationOptions);
  
  notification.onclick = () => {
    notification.close();
    window.focus();
    this.handleNotificationClick(payload.data);
  };
}

showNotificationInApp(notification: PushNotificationSchema): void {
  this.apiService.showNotification(
    'snackbar-info',
    notification.title || 'New Notification',
    5000
  );
}

handleNotificationClick(data: any): void {
  if (!data) return;
  
  if (data.type === 'message') {
    this.router.navigate(['/messages', data.messageId]);
  } else if (data.type === 'assignment') {
    this.router.navigate(['/assignments', data.assignmentId]);
  } else if (data.type === 'announcement') {
    this.router.navigate(['/announcements']);
  } else if (data.url) {
    this.router.navigateByUrl(data.url);
  }
}

  

  compareVersions(version1: string, version2: string): number {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);

    // Normalize lengths by adding trailing zeroes if necessary
    const maxLength = Math.max(v1Parts.length, v2Parts.length);
    while (v1Parts.length < maxLength) v1Parts.push(0);
    while (v2Parts.length < maxLength) v2Parts.push(0);

    for (let i = 0; i < maxLength; i++) {
      if (v1Parts[i] > v2Parts[i]) return 1; // version1 is greater
      if (v1Parts[i] < v2Parts[i]) return -1; // version2 is greater
    }
    return 0; // versions are equal
  }

  sendTokenBackend(token: string): void {
    const body: any = {
      schoolId: this.authService.currentUserValue.school_id,
      firebaseId: token,
      deviceName: navigator.userAgent, 
      ...(this.authService.currentUserValue.role === Role.Admin && { adminId: this.authService.currentUserValue.id }),
      ...(this.authService.currentUserValue.role === Role.Teacher && { teacherId: this.authService.currentUserValue.id }),
      ...(this.authService.currentUserValue.role === Role.Student && { studentId: this.authService.currentUserValue.id }),
    };
    this.authService.firebasePushIdUpdate(body).subscribe((res) => {
      console.log('Firebase token sent to backend:', res);
    });
  }

  hasUpdate(currentVersion: string, latestVersion: string): boolean {
    return this.compareVersions(currentVersion, latestVersion) === -1;
  }
}
