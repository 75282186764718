import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import packageJson from '../../../../../../package.json';
import { Role } from '../../../models/role';
import { AuthService } from '../../../services/auth.service';
import { RightSidebarService } from '../../../services/rightsidebar.service';
import { ROUTES } from './sidebar-items';
import { RouteInfo } from './sidebar.metadata';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj: any = null;
  public role: any;
  appVersion: string;
  routerList: RouteInfo[] = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private sidebarService: RightSidebarService
  ) {
    this.routerList = ROUTES;
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event: any) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const activeClass = parentElement.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }
  ngOnInit() {
    if (this.authService.currentUserValue) {
      this.role = this.authService.currentUserValue.role;
      const schoolid = this.authService.currentUserValue.school_id;
      const userRole = this.authService.currentUserValue.role;
      if (
        this.authService.currentUserValue?.entitytype?.toLowerCase() ==
        'college'
      ) {
        this.routerList = this.routerList.map((route) => {
          if (route.title === 'MENUITEMS.CLASS.TEXT') {
            if (route.submenu) {
              route.submenu = route.submenu.map((subItem) => {
                if (
                  subItem.title ===
                  'MENUITEMS.SubjectClassTeacher.LIST.SubjectClassTeacher'
                ) {
                  subItem.title =
                    'MENUITEMS.SubjectClassProfessor.LIST.SubjectClassProfessor';
                }
                return subItem;
              });
            }
          }

          if (route.title === 'MENUITEMS.HOUSE.TEXT') {
            route.title = 'MENUITEMS.CLUB.TEXT';
            if (route.submenu) {
              route.submenu = route.submenu.map((subItem) => {
                if (subItem.title === 'MENUITEMS.HOUSE.LIST.ALL-HOUSE') {
                  subItem.title = 'MENUITEMS.CLUB.LIST.ALL-CLUB';
                }
                if (subItem.title === 'MENUITEMS.HOUSE.LIST.ADD-HOUSE') {
                  subItem.title = 'MENUITEMS.CLUB.LIST.ADD-CLUB';
                }
                return subItem;
              });
            }
          }
          if (route.title === 'MENUITEMS.ATTENDANCE.TEXT') {
            if (route.submenu) {
              route.submenu = route.submenu.map((subItem) => {
                if (subItem.title === 'MENUITEMS.ATTENDANCE.LIST.TEACHER') {
                  subItem.title = 'MENUITEMS.ATTENDANCE.LIST.PROFESSORS';
                }
                return subItem;
              });
            }
          }
          if (route.title === 'MENUITEMS.LEAVE.TEXT') {
            if (route.submenu) {
              route.submenu = route.submenu.map((subItem) => {
                if (subItem.title === 'MENUITEMS.LEAVE.LIST.TEACHER') {
                  subItem.title = 'MENUITEMS.LEAVE.LIST.PROFESSOR';
                }
                return subItem;
              });
            }
          }
          if (route.title === 'MENUITEMS.TEACHERS.TEXT') {
            route.title = 'MENUITEMS.PROFESSORS.TEXT';
            if (route.submenu) {
              route.submenu = route.submenu.map((subItem) => {
                if (subItem.title === 'MENUITEMS.TEACHERS.LIST.ALL-TEACHERS') {
                  subItem.title = 'MENUITEMS.PROFESSORS.LIST.ALL-PROFESSORS';
                }
                if (subItem.title === 'MENUITEMS.TEACHERS.LIST.ADD-TEACHER') {
                  subItem.title = 'MENUITEMS.PROFESSORS.LIST.ADD-PROFESSORS';
                }
                if (
                  subItem.title ===
                  'MENUITEMS.TEACHERS.LIST.SUBSTITUTE-TEACHERS'
                ) {
                  subItem.title =
                    'MENUITEMS.PROFESSORS.LIST.SUBSTITUTE-PROFESSORS';
                }
                return subItem;
              });
            }
          }
          if (route.title === 'MENUITEMS.STUDENT.LIST.HOMEWORK') {
            route.title = 'MENUITEMS.STUDENT.LIST.ASSIGNMENT';
          }
          if (route.title === 'MENUITEMS.TEACHER.LIST.HOMEWORK') {
            route.title = 'MENUITEMS.TEACHER.LIST.ASSIGNMENT';
          }

          return route;
        });
      } else {
        // Hide Student Attendance components for school context
        const hiddenPaths = [
          '/teacher/student-attendance-new',
          '/student/attendance-new',
          '/admin/students/student-attendance-new',
        ];
        this.routerList = this.filterRoutes(this.routerList, hiddenPaths);
      }
      if (
        userRole === 'Admin' &&
        this.authService.currentUserValue.department_name !== 'super admin'
      ) {
        this.sidebarService
          .getAccessDetails(this.authService.currentUserValue.id)
          .subscribe(
            (result: any) => {
              this.sidebarItems = [];
              const items = this.routerList;
              // Handling Internal Marks sidebar item
              const exam = items.find((item) => item.component === 'Exam');
              const exist = exam.submenu.find(
                (menu) =>
                  menu.component === 'Assessment Result' && !menu.newInternal
              );
              if (!exist) {
                const router: any = this.getInternalMarksPath(
                  userRole,
                  schoolid !== 4
                );
                exam.submenu.splice(3, 0, ...router);
              }
              for (let i = 0; i < result.length; i++) {
                this.sidebarItems = this.getAccessibleItems(
                  JSON.parse(result[i]?.write_access || null),
                  JSON.parse(result[i]?.read_access || null),
                  this.routerList
                );
              }
              this.userType = this.authService.currentUserValue.department_name;
            },
            (error: any) => {
              /** */
            }
          );
      } else {
        this.userFullName = this.authService.currentUserValue.username;

        this.userImg = this.authService.currentUserValue.image;

        this.sidebarItems = this.routerList.filter((x) => {
          if (
            x.role.includes(userRole) &&
            (x.component === 'Exam' ||
              x.component === 'Teacher Exam Result' ||
              (x.component === 'Student Exam Result' && schoolid !== 4))
          ) {
            // Ignoring internal mark in adrian student login
            const exist = x.submenu.find(
              (menu) =>
                menu.component === 'Assessment Result' && !menu.newInternal
            );
            if (!exist) {
              const router: any = this.getInternalMarksPath(
                userRole,
                schoolid !== 4
              );
              const lastElementIndex = x.submenu.length - 1;
              x.submenu.splice(lastElementIndex, 0, ...router);
            }
          }
          return (
            x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
          );
        });
        if (userRole === Role.Teacher) {
          this.sidebarItems = this.sidebarItems.filter((item: any) =>
            this.authService.currentUserValue.class_ids
              ? item
              : item.component !== 'student'
          );
        }
        if (userRole === Role.Admin) {
          this.userType = this.authService.currentUserValue.department_name;
        } else if (userRole === Role.Teacher) {
          this.userType = this.authService.currentUserValue.department_name;
        } else if (userRole === Role.Student) {
          this.userType = Role.Student;
        } else {
          this.userType = Role.Admin;
        }
      }
    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
    this.appVersion = packageJson.version;
  }

  getInternalMarksPath(userRole: string, isNew: boolean): any {
    return [
      {
        path: `/${userRole.toLowerCase()}/examresult/${
          isNew ? 'internal-marks' : 'assessment-result'
        }`,
        title: 'MENUITEMS.EXAM.LIST.ASSESSMENT-MARKS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [userRole],
        submenu: [],
        component: 'Assessment Result',
      },
    ];
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  filterRoutes(routes: any[], hiddenPaths: any): any[] {
    return routes.filter((route) => {
      // Remove top-level routes based on hiddenPaths
      const shouldRemove = hiddenPaths.includes(route.path);

      // Remove route if it matches any hidden path
      if (shouldRemove) return false;

      // Recursively filter submenus
      if (route.submenu && route.submenu.length > 0) {
        route.submenu = this.filterRoutes(route.submenu, hiddenPaths);
      }

      // Keep route if it does not match hidden paths
      return true;
    });
  }

  getAccessibleItems(writeAccess: any, readAccess: any, items: any) {
    const sidebarItems = [];
    if (readAccess || writeAccess) {
      for (let i = 0; i < items.length; i++) {
        let sidebarItem;
        if (items[i].component === 'Main') {
          sidebarItems.push(items[i]);
        }
        if (items[i].component == 'Scheduler') {
          let indexI = writeAccess?.indexOf('Timetable');
          if (indexI > -1) {
            writeAccess[indexI] = 'Scheduler';
          }
          let indexJ = readAccess?.indexOf('Timetable');
          if (indexJ > -1) {
            readAccess[indexJ] = 'Scheduler';
          }
        }
        if (
          writeAccess?.indexOf(items[i].component) > -1 ||
          readAccess?.indexOf(items[i].component) > -1
        ) {
          sidebarItem = items[i];
          if (sidebarItem.submenu.length) {
            sidebarItem.submenu = this.getAccessibleItems(
              writeAccess,
              readAccess,
              sidebarItem.submenu
            );
          }
          sidebarItems.push(sidebarItem);
        }
      }
    }
    return sidebarItems;
  }

  initLeftSidebar() {
    // Set menu height
    this.setMenuHeight();
    this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime: any) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }
}
