// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isAdminApp: true,
  isTeacherApp: true,
  isStudentApp: true,
  apiUrl: 'https://dev-api.klartopedia.in/',

  // apiUrl: 'http://localhost:3000/',
  apiKey: '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',
  firebase: {
    apiKey: 'AIzaSyAuevkemTTCmsr8dd9raFmrSnxGHXOaTOM',
    authDomain: 'klartopedia-staging.firebaseapp.com',
    projectId: 'klartopedia-staging',
    storageBucket: 'klartopedia-staging.appspot.com',
    messagingSenderId: '746886112169',
    appId: '1:746886112169:web:dde279083cdca7de6edab8',
    measurementId: 'G-M9RG2Q7GMJ',
    vapidKey:
      'BMwh3pNeMvb7BEQRrxvLcfFD-qfUHlsWaiM_akjlY7zI93s1EY70LTt5RpA4jdAF9y7aqBELH0FLcEHPuB0G2Qo',
  },
  googleApiKey: 'AIzaSyDkCKF48ydqger-dLQI9tmfmOFY9JxSo1M',
  EaseBuzz: {
    EASEBUZZ_KEY: 'Z9NHVY7G41',
    EASEBUZZ_SALT: 'WYZ2OVE1AU',
  },
  openAiChatCompletionUrl:
    'https://voice-buddy-api-test.openai.azure.com/openai/deployments/gpt-35-turbo-16k/chat/completions?api-version=2024-08-01-preview',
  openAiChatCompletionApiKey:
    'SFgWCTFI62iJxOhL3U24DMmOyYy8mgihB7K2hYFiBNUZH76vUr5ZJQQJ99BCACHYHv6XJ3w3AAABACOGEUVa',
  openAiVoiceBotApiKey:
    '3zi3OPAL2C8NLlOkUD5RHpEf00jbxK7cEw5ooaIdxJrlgAAKYDqsJQQJ99BCACHYHv6XJ3w3AAAAACOGEBRU',
  openAiVoiceBotUrl:
    'https://voicebuddy.cognitiveservices.azure.com/openai/realtime?api-version=2024-10-01-preview&deployment=gpt-4o-realtime-preview',
  azureSpeechServiceKey:
    '4GpGcmBJsSjFZNy6cvd4qbCaC9JGQ6PSaK1QFKTqfgxdBx7SumabJQQJ99AKACYeBjFXJ3w3AAAYACOGs8zU',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
