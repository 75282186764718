// sip.service.ts
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as JsSIP from 'jssip';
import {
  RTCSession,
  RTCSessionEventMap,
  IncomingEvent,
  OutgoingEvent,
  EndEvent,
  PeerConnectionEvent,
  ConnectingEvent,
  SDPEvent,
} from 'jssip/lib/RTCSession';
import {
  CallOptions,
  RTCSessionEvent,
  IncomingMessageEvent,
  OutgoingMessageEvent,
} from 'jssip/lib/UA';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { find } from 'rxjs/operators';
import { AuthService } from './auth.service';

export interface SipConfig {
  sipUri: string;
  sipPassword: string;
  wsUri: string;
}

export interface CallState {
  isInCall: boolean;
  callStatus:
    | 'idle'
    | 'connecting'
    | 'confirmed'
    | 'ended'
    | 'failed'
    | 'progress';
  callerId?: string;
  callerName?: string;
  duration?: number;
  isMuted?: boolean;
  isSpeakerOn?: boolean;
  isOnHold?: boolean; // Add this property
}

export interface RegistrationState {
  isRegistered: boolean;
  status: 'disconnected' | 'connecting' | 'registered' | 'failed';
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SipService {
  private userAgent: JsSIP.UA;
  public localStream: MediaStream = null;
  private outgoingSession: RTCSession;
  private incomingSession: RTCSession;
  public currentSession: RTCSession;
  private callStateSubject = new BehaviorSubject<CallState>({
    isInCall: false,
    callStatus: 'idle',
  });

  private registrationStateSubject = new BehaviorSubject<RegistrationState>({
    isRegistered: false,
    status: 'disconnected',
  });

  onCall: boolean = false;

  private constraints: MediaStreamConstraints = {
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
    },
  };

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  // Register callbacks to desired call events
  private eventHandlers: Partial<RTCSessionEventMap> = {
    progress: (e: IncomingEvent | OutgoingEvent) => {
      console.log(
        '%cCall is in progress',
        'color:black;background-color:yellow',
        e
      );
      if (!this.authService.currentUserValue.isIncomingEnabled) {
        return;
      }
      this.onCall = true;
      this.updateCallState({ callStatus: 'progress' });
      // this.snackBar.open('Call is in progress', null, { duration: 2000 });
    },
    failed: (e: EndEvent) => {
      console.error('%cCall failed: ', e);
      this.onCall = false;
      this.updateCallState({ isInCall: false, callStatus: 'failed' });
      // this.snackBar.open('Call failed', 'Close');
    },
    ended: (e: EndEvent) => {
      console.log('%cCall ended : ', 'color:white;background-color:red', e);
      this.updateCallState({ isInCall: false, callStatus: 'ended' });
      this.onCall = false;
      // this.snackBar.open('Call ended', 'Close');
    },
    confirmed: (e: IncomingEvent | OutgoingEvent) => {
      console.log(
        '%cCall confirmed',
        'color:black;background-color:lightgreen',
        e
      );
      this.updateCallState({ callStatus: 'confirmed' });
      // this.snackBar.open('Call is in progress', null, { duration: 2000 });
    },
    peerconnection: (e: PeerConnectionEvent) => {
      console.log(
        '%cOn peerconnection',
        'color:black;background-color:orange',
        e
      );
      // this.snackBar.open('On peerconnection', null, { duration: 3000 });
    },
  };

  private callOptions: CallOptions = {
    eventHandlers: this.eventHandlers,
    mediaConstraints: {
      audio: true,
      video: false,
    },
    mediaStream: this.localStream,
  };

  get callState$(): Observable<CallState> {
    return this.callStateSubject.asObservable();
  }

  get registrationState$(): Observable<RegistrationState> {
    return this.registrationStateSubject.asObservable();
  }

  async setupLocalMedia(): Promise<MediaStream> {
    try {
      console.log('Requesting local audio');
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia(
        this.constraints
      );
      this.localStream = stream;
      console.log('Received local media stream', stream);
      // this.snackBar.open('Received local media stream', null, {
      //   duration: 2000,
      // });
      return stream;
    } catch (error) {
      console.log('getUserMedia() error: ' + error);
      // this.snackBar.open('Get User Media Error', 'Close');
      throw error;
    }
  }

  async getConnectedDevices(
    type: 'audioinput' | 'audiooutput'
  ): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter((device) => device.kind === type);
  }

  /**
   * Initialize SIP User Agent
   */
  initialize(config: SipConfig): Observable<boolean> {
    // Update registration state
    this.updateRegistrationState({
      status: 'connecting',
    });

    // this.setupLocalMedia();

    const socket = new JsSIP.WebSocketInterface(config.wsUri);
    const configuration = {
      sockets: [socket],
      outbound_proxy_set: config.wsUri,
      uri: config.sipUri,
      password: config.sipPassword,
      register: true,
      session_timers: false,
    };

    this.userAgent = new JsSIP.UA(configuration);

    this.registerEventHandlers();
    this.userAgent.start();
    console.log('UA started');

    // Return an observable that emits when registered
    return new Observable<boolean>((observer) => {
      this.userAgent.on('registered', () => {
        observer.next(true);
        observer.complete();
        this.updateRegistrationState({
          isRegistered: true,
          status: 'registered',
        });
      });

      this.userAgent.on('registrationFailed', (event) => {
        observer.error(new Error(`Registration failed: ${event.cause}`));
        this.updateRegistrationState({
          status: 'failed',
          errorMessage: 'Failed to create SIP connection',
        });
      });
    });
  }

  private registerEventHandlers(): void {
    this.userAgent.on('registered', (registeredEvent) => {
      console.log(
        'registered: ',
        registeredEvent.response.status_code,
        ',',
        registeredEvent.response.reason_phrase
      );
      // this.snackBar.open(
      //   `Registered: ${registeredEvent.response.status_code}, ${registeredEvent.response.reason_phrase}`,
      //   null,
      //   { duration: 2000 }
      // );
    });

    this.userAgent.on('registrationFailed', (unRegisteredEvent) => {
      console.warn(
        'registrationFailed, ',
        unRegisteredEvent.response.status_code,
        ',',
        unRegisteredEvent.response.reason_phrase,
        ' cause - ',
        unRegisteredEvent.cause
      );
      // this.snackBar.open(`Registration Failed`, 'Close');
    });

    this.userAgent.on('registrationExpiring', () => {
      console.warn('registrationExpiring');
      // this.snackBar.open('Registration Expiring', 'Close');
    });

    this.userAgent.on('newRTCSession', this.handleNewRTCSession.bind(this));

    this.userAgent.on(
      'newMessage',
      (data: IncomingMessageEvent | OutgoingMessageEvent) => {
        if (data.originator === 'local') {
          console.log('onNewMessage, OutgoingRequest - ', data.request);
        } else {
          console.log('onNewMessage, IncomingRequest - ', data.request);
        }
      }
    );
  }

  private handleNewRTCSession(sessionEvent: RTCSessionEvent): void {
    console.log('onNewRTCSession: ', sessionEvent);

    if (sessionEvent.originator === 'remote') {
      if (this.onCall) {
        console.log('onCall, reject the call');
        sessionEvent.session.terminate();
        return;
      }

      // incoming call
      this.incomingSession = sessionEvent.session;
      this.currentSession = this.incomingSession;
      console.log('incomingSession, answer the call', this.incomingSession);

      this.updateCallState({
        isInCall: true,
        callStatus: 'idle',
        callerId: sessionEvent.request.from.uri.user,
        callerName:
          sessionEvent.request.from.display_name ||
          sessionEvent.request.from.uri.user,
      });

      this.updateCallState({
        isInCall: false,
        callStatus: 'connecting',
        callerId: sessionEvent.request.from.uri.user,
        callerName:
          sessionEvent.request.from.display_name ||
          sessionEvent.request.from.uri.user,
      });

      // Auto answer for now - can be controlled via a method later
      // this.currentSession.answer({
      //   mediaConstraints: this.callOptions.mediaConstraints,
      //   mediaStream: this.localStream,
      // });
    } else {
      console.log('outgoingSession');
      this.outgoingSession = sessionEvent.session;
      this.outgoingSession.on('connecting', (event: ConnectingEvent) => {
        console.log('onConnecting - ', event.request);
        this.currentSession = this.outgoingSession;
        this.outgoingSession = null;
        console.log('call session', this.currentSession);
      });
    }

    this.attachSessionEventHandlers(sessionEvent.session);
  }

  private attachSessionEventHandlers(session: RTCSession): void {
    session.on('accepted', (event: IncomingEvent | OutgoingEvent) => {
      console.log('onAccepted - ', event);
      if (event.originator === 'remote' && this.currentSession == null) {
        this.currentSession = this.incomingSession;
        this.incomingSession = null;
        console.log('accepted setCurrentSession - ', this.currentSession);
      }
    });

    session.on('ended', (event: EndEvent) => {
      console.log('%conEnded - ', 'color:white;background-color:red', event);
      if (event.originator === 'remote') {
        this.currentSession = null;
        this.onCall = false;
        this.updateCallState({ isInCall: false, callStatus: 'ended' });
      }
    });

    session.on('confirmed', (event: IncomingEvent | OutgoingEvent) => {
      console.log(
        '%conConfirmed - ',
        'color:black;background-color:lightgreen',
        event
      );
      if (event.originator === 'remote') {
        this.updateCallState({ isInCall: true, callStatus: 'confirmed' });
      }
      // if (event.originator === 'remote' && this.currentSession == null) {
      //   this.currentSession = this.incomingSession;
      //   this.incomingSession = null;
      //   console.log('confirmed setCurrentSession - ', this.currentSession);
      // }
    });

    session.on('sdp', (event: SDPEvent) => {
      console.log('onSDP, type - ', event.type, ' sdp - ', event.sdp);
    });

    session.on('progress', (event: IncomingEvent | OutgoingEvent) => {
      if (!this.authService.currentUserValue.isIncomingEnabled) {
        return;
      }
      this.onCall = true;
      console.log(
        '%conProgress - ',
        'color:black;background-color:yellow',
        event.originator
      );
      if (event.originator === 'remote') {
        console.log(
          '%conProgress, response - ',
          'color:black;background-color:yellow',
          event.response
        );
      }
    });

    session.on('peerconnection', (event: PeerConnectionEvent) => {
      console.log(
        '%conPeerconnection - ',
        'color:black;background-color:orange',
        event.peerconnection
      );
    });
  }

  /**
   * Make an outgoing call
   */
  makeCall(phoneNumber: string): void {
    if (!this.userAgent || !this.userAgent.isRegistered()) {
      this.snackBar.open('Not registered with SIP server', 'Close');
      return;
    }

    if (this.onCall) {
      this.snackBar.open('Please close the incoming call', 'Close');
      return;
    }

    this.updateCallState({
      isInCall: true,
      callStatus: 'connecting',
      callerId: phoneNumber,
    });

    const options: CallOptions = {
      ...this.callOptions,
      mediaStream: this.localStream,
    };

    this.outgoingSession = this.userAgent.call(phoneNumber, options);
  }

  /**
   * End the current call
   */
  hangUp(): void {
    if (this.currentSession?.status == 8) {
      this.currentSession = null;
      this.outgoingSession = null;
      this.incomingSession = null;
      return;
    }
    if (this.currentSession) {
      this.currentSession.terminate();
    } else if (this.outgoingSession) {
      this.outgoingSession.terminate();
    } else if (this.incomingSession) {
      this.incomingSession.terminate();
    } else {
      this.userAgent?.terminateSessions();
    }
    this.onCall = false;
    this.updateCallState({ isInCall: false, callStatus: 'ended' });
  }

  /**
   * Toggle mute state
   */
  toggleMute(): boolean {
    if (!this.currentSession || !this.localStream) {
      return false;
    }

    const isMuted = this.callStateSubject.value.isMuted || false;
    const newMuteState = !isMuted;

    this.localStream.getAudioTracks().forEach((track) => {
      track.enabled = !newMuteState;
    });

    this.updateCallState({ isMuted: newMuteState });
    return newMuteState;
  }

  /**
   * Toggle speaker
   */
  toggleSpeaker(): boolean {
    const isSpeakerOn = this.callStateSubject.value.isSpeakerOn || false;
    const newSpeakerState = !isSpeakerOn;
    this.currentSession.mute();
    // Implementation would depend on how you control audio output
    // This is a placeholder for the state change
    this.updateCallState({ isSpeakerOn: newSpeakerState });
    return newSpeakerState;
  }

  /**
   * Toggle hold state
   */
  toggleHold(): boolean {
    if (!this.currentSession) {
      return false;
    }

    const isOnHold = this.callStateSubject.value.isOnHold || false;
    const newHoldState = !isOnHold;

    try {
      if (newHoldState) {
        // Put call on hold
        this.currentSession.hold();
        console.log('Call placed on hold');
      } else {
        // Resume call
        this.currentSession.unhold();
        console.log('Call resumed');
      }

      this.updateCallState({ isOnHold: newHoldState });
      return newHoldState;
    } catch (error) {
      console.error('Error toggling hold state:', error);
      return isOnHold; // Return the original state if there was an error
    }
  }

  /**
   * Send DTMF
   */
  sendDTMF(tone: string): void {
    if (this.currentSession) {
      this.currentSession.sendDTMF(tone);
    }
  }

  /**
   * Update the registration state
   */
  private updateRegistrationState(update: Partial<RegistrationState>): void {
    this.registrationStateSubject.next({
      ...this.registrationStateSubject.value,
      ...update,
    });
  }

  private updateCallState(update: Partial<CallState>): void {
    this.callStateSubject.next({
      ...this.callStateSubject.value,
      ...update,
    });
  }

  ngOnDestroy(): void {
    this.unregister();
  }

  /**
   * Unregister from SIP server
   */
  unregister(): void {
    if (this.userAgent) {
      // Terminate any active sessions
      this.hangUp();

      try {
        // Unregister from the SIP server
        this.userAgent.unregister();

        // Stop the user agent
        this.userAgent.stop();

        console.log('SIP service unregistered');
      } catch (error) {
        console.warn('Error during unregister:', error);
      }

      this.userAgent = null;
    }

    // Clean up media streams
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
      this.localStream = null;
    }

    // this.updateCallState({ isInCall: false, callStatus: 'idle' });
  }
}
