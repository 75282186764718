import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CallState, SipService } from 'src/app/shared/services/sip.service';
import { interval, skip, Subject, Subscription, takeUntil } from 'rxjs';

export interface CallDialogData {
  phoneNumber?: string;
  contactName?: string;
  isIncoming: boolean;
  callState?: CallState;
}

@Component({
  selector: 'app-call-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  animations: [
    trigger('pulse', [
      state('normal', style({ transform: 'scale(1)' })),
      state('pulse', style({ transform: 'scale(1.1)' })),
      transition('normal <=> pulse', animate('500ms ease-in-out')),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './call-dialog.component.html',
  styleUrls: ['./call-dialog.component.scss'],
})
export class CallDialogComponent implements OnInit, OnDestroy {
  @ViewChild('remoteAudio') remoteAudioElement: ElementRef;

  // Call state variables
  callStatusText: string = 'Calling...';
  contactName: string = 'Unknown';
  contactNumber: string = '';
  contactInitials: string = '';
  isConnected: boolean = false;
  callDuration: number = 0;
  isIncoming: boolean = false;

  // UI control variables
  isOnHold = false;
  isMuted: boolean = false;
  isSpeakerOn: boolean = false;
  showKeypad: boolean = false;
  isPulsing: boolean = true;
  pulseState: string = 'normal';
  connectingDot: number = 1;

  // Audio elements
  localAudio: HTMLAudioElement;
  remoteAudio: HTMLAudioElement;

  // Keypad configuration
  keypadRows = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#'],
  ];

  // Subscriptions
  private timerSubscription?: Subscription;
  private pulseSubscription?: Subscription;
  private dotsSubscription?: Subscription;

  get showIncomingUI(): boolean {
    return this.isIncoming && !this.isConnected && !this.timerSubscription;
  }

  get showOutgoingUI(): boolean {
    return !this.isIncoming && !this.isConnected;
  }

  get showActiveCallUI(): boolean {
    return this.isConnected;
  }

  constructor(
    private dialogRef: MatDialogRef<CallDialogComponent>,
    private sipService: SipService,
    @Inject(MAT_DIALOG_DATA) private data: CallDialogData
  ) {
    this.isIncoming = data.isIncoming ?? false;
    this.contactName = data.contactName || 'Unknown';
    this.contactNumber =
      data.phoneNumber || data.callState?.callerId || 'Unknown';
    this.contactInitials = this.getInitials(this.contactName);

    if (this.isIncoming) {
      this.callStatusText = 'Incoming Call';
    } else {
      this.callStatusText = 'Calling...';
    }
  }

  ngOnInit(): void {
    if (!this.isIncoming) {
      this.startDotAnimation();
      this.startPulseAnimation();
    }

    this.sipService.callState$.pipe(skip(1)).subscribe((state) => {
      if (state.callStatus === 'confirmed') {
        this.isConnected = true;
        this.callStatusText = 'Connected';
        this.isPulsing = false;
        if (this.dotsSubscription) {
          this.dotsSubscription.unsubscribe();
        }
        this.startTimer();
      } else if (
        state.callStatus === 'ended' ||
        state.callStatus === 'failed'
      ) {
        // First stop all audio tracks
        this.dialogRef.close();
      } else if (state.callStatus === 'progress') {
        this.callStatusText = 'Ringing...';
      }

      this.isMuted = state.isMuted || false;
      this.isSpeakerOn = state.isSpeakerOn || false;
      this.isOnHold = state.isOnHold || false; // Update this line
    });

    // For outgoing calls, initiate the call
    if (!this.isIncoming) {
      this.sipService.setupLocalMedia().then(() => {
        this.sipService.makeCall(this.contactNumber);
      });
    }
  }

  ngAfterViewInit(): void {
    this.remoteAudio = this.remoteAudioElement.nativeElement;

    // Set up remote audio tracks when they become available
    this.setupMediaHandling();
  }

  ngOnDestroy(): void {
    this.sipService.hangUp();
    // Stop all audio tracks first
    this.stopAllAudioTracks();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.pulseSubscription) {
      this.pulseSubscription.unsubscribe();
    }
    if (this.dotsSubscription) {
      this.dotsSubscription.unsubscribe();
    }

    // Clear audio elements
    if (this.remoteAudio) {
      this.remoteAudio.srcObject = null;
    }
    if (this.localAudio) {
      this.localAudio.srcObject = null;
    }
  }

  setupMediaHandling(): void {
    // Create a subscription to handle track events
    this.sipService.callState$.subscribe((state) => {
      if (
        (state.callStatus === 'progress' || state.callStatus === 'confirmed') &&
        this.sipService.currentSession
      ) {
        const pc = this.sipService.currentSession.connection;

        // Check if there are already remote tracks
        const receivers = pc.getReceivers();
        const audioReceiver = receivers.find(
          (receiver: any) => receiver.track && receiver.track.kind === 'audio'
        );

        if (audioReceiver && audioReceiver.track) {
          console.log('Found existing audio track');
          const stream = new MediaStream([audioReceiver.track]);
          this.remoteAudio.srcObject = stream;
          this.remoteAudio
            .play()
            .catch((error) =>
              console.error('Error playing remote audio:', error)
            );
        }

        // Also listen for new tracks
        pc.addEventListener('track', (event: RTCTrackEvent) => {
          console.log('Received track event:', event);
          if (event.track.kind === 'audio') {
            console.log('Received remote audio track');
            const stream = new MediaStream([event.track]);
            this.remoteAudio.srcObject = stream;
            this.remoteAudio
              .play()
              .catch((error) =>
                console.error('Error playing remote audio:', error)
              );
          }
        });
      }
    });
  }

  startPulseAnimation(): void {
    this.pulseSubscription = interval(500).subscribe(() => {
      this.pulseState = this.pulseState === 'normal' ? 'pulse' : 'normal';
    });
  }

  startDotAnimation(): void {
    this.dotsSubscription = interval(500).subscribe(() => {
      this.connectingDot = (this.connectingDot % 3) + 1;
    });
  }

  startTimer(): void {
    this.timerSubscription = interval(1000).subscribe(() => {
      this.callDuration++;
    });
  }

  formatTime(seconds: number): string {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  }

  acceptCall(): void {
    // Set up local media first
    this.sipService
      .setupLocalMedia()
      .then((stream) => {
        // Answer the current session
        if (this.sipService.currentSession) {
          this.sipService.currentSession.answer({
            mediaConstraints: { audio: true, video: false },
            mediaStream: stream,
          });
          this.startTimer();
          this.isConnected = true;
        }
      })
      .catch((error) => {
        console.error('Failed to get media for call', error);
      });
  }

  rejectCall(): void {
    this.sipService.hangUp();
    this.dialogRef.close();
  }

  toggleMute(): void {
    this.isMuted = this.sipService.toggleMute();
  }

  toggleSpeaker(): void {
    this.isSpeakerOn = this.sipService.toggleSpeaker();
  }

  toggleHold(): void {
    this.isOnHold = this.sipService.toggleHold();
  }

  toggleKeypad(): void {
    this.showKeypad = !this.showKeypad;
  }

  sendDtmf(key: string): void {
    this.sipService.sendDTMF(key);
  }

  getKeyLetters(key: string): string {
    const keyMap: { [key: string]: string } = {
      '1': '',
      '2': 'ABC',
      '3': 'DEF',
      '4': 'GHI',
      '5': 'JKL',
      '6': 'MNO',
      '7': 'PQRS',
      '8': 'TUV',
      '9': 'WXYZ',
      '0': '+',
      '*': '',
      '#': '',
    };
    return keyMap[key] || '';
  }

  endCall(): void {
    this.sipService.hangUp();
    this.dialogRef.close();
  }

  private getInitials(name: string): string {
    if (!name || name === 'Unknown') return '?';

    const parts = name.split(' ');
    if (parts.length === 1) {
      return parts[0].charAt(0).toUpperCase();
    }

    return (
      parts[0].charAt(0) + parts[parts.length - 1].charAt(0)
    ).toUpperCase();
  }

  stopAllAudioTracks(): void {
    // Clean up remote audio
    if (this.remoteAudio && this.remoteAudio.srcObject) {
      const remoteStream = this.remoteAudio.srcObject as MediaStream;
      remoteStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.remoteAudio.srcObject = null;
    }

    // Clean up local audio
    if (this.localAudio && this.localAudio.srcObject) {
      const localStream = this.localAudio.srcObject as MediaStream;
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.localAudio.srcObject = null;
    }

    if (this.sipService.localStream != null) {
      const sipServiceStream = this.sipService.localStream as MediaStream;
      sipServiceStream.getTracks().forEach((track) => {
        console.log('Stopping SIP service track:', track);
        track.stop();
      });
    }
  }
}
